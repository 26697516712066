import React, { useEffect, useState } from "react";
import { Button, Col, Row, Table } from "react-bootstrap";
import "./table.css";
import moment from "moment";
import { CSVLink } from "react-csv";
import toast from "react-hot-toast";
import InvoiceService from "../../services/invoice.service";
import AllInvoicesByInstructor from "../Modals/AllInvoicesByInstructor.modal";

interface IUnpaidInvoice {
  pageCount: any
  pageNumber: any
  startDate: any
  endDate: any 
  instructor: any;
}

export default function UnpaidInvoicesTableForPerClass({
    pageCount,
    pageNumber,
    startDate,
    endDate,
    instructor
}: IUnpaidInvoice) {

  const [tableData, setTableData] = useState<any>();
  const [csvData, setCsvData] = useState<any>();
  const [loading, setLoading] = useState(false);

  const getInvoices = async () => {
    if(!moment(startDate).isValid() || !moment(endDate).isValid()){
      return;
    }
    const fromDate = moment(startDate).format("YYYY-MM-DD");
     const toDate = moment(endDate).format("YYYY-MM-DD");
 
     
   await 
      InvoiceService.getInvoices(pageCount, pageNumber, "per_class", fromDate, toDate, instructor?.value).then((res: any) => {
        if (res.status === 200) {
          setTableData(res?.data.invoices);
        }
      })
        .catch((err: any) => {
          // toast.error("Invoice Details not found.");
          console.log(err);
        })
  }

  const getCSVData = async () => {
    setLoading(true);
    if(!moment(startDate).isValid() || !moment(endDate).isValid()){
      return;
    }
    const fromDate = moment(startDate).format("YYYY-MM-DD");
     const toDate = moment(endDate).format("YYYY-MM-DD");
 
     
   await 
      InvoiceService.getInvoices(1000000000, 1, "per_class", fromDate, toDate, instructor?.value).then((res: any) => {
        if (res.status === 200) {
          setCsvData(res?.data.invoices.map((data: any) => {
            return {
              "Receipt Id": data.receipt_id,
              "Instructor": data.instructor_name,
              "Enrollment Id": data.enrollment_id,
              "Schedule Id": data.schedule_id,
              "Amount": data.amount,
              "Date": moment(data.date1).format("DD/MM/YYYY hh:mm a"),
              "Comments": data.comments
            }
          }));
        }
      })
        .catch((err: any) => {
          // toast.error("Invoice Details not found.");
          console.log(err);
        })
    setLoading(false);
  }




  useEffect(() => {
    getInvoices()
  }, [pageCount, startDate, endDate, instructor]);
  return (
    <>
      <div className="d-flex justify-content-end align-items-center">
      <Button size="sm" onClick={getCSVData} disabled={loading}>
        { loading ? "Creating CSV" : "Create CSV" } 
      </Button>
      {csvData &&
      <CSVLink data={csvData} filename={"per_class.csv"}>
              <Button size="sm" className="mx-2">
                Download CSV Data
              </Button>
      </CSVLink>
      }
      </div>
      <Table striped borderless hover responsive size="sm">
        <thead>
          <tr className="dhun-table-header text-secondary">
            <th>Receipt Id</th>
            <th>Instructor</th>
            <th>Enrollment Id</th>
            <th>Schedule Id</th>
            <th>Amount</th>
            <th>Date</th>
            <th>Comments</th>
          </tr>
        </thead>
        <tbody>
          {tableData && tableData?.length > 0
            ? tableData?.map((data: any, index: any) => {
                return (
                  <tr >
                    <td>
                      {data.receipt_id}
                    </td>
                    <td>
                      {data.instructor_name}
                    </td>
                    <td>
                     {data.enrollment_id}
                    </td>
                    <td>
                     {data.schedule_id}
                    </td>
                    <td>
                      {data.amount}
                    </td>
                    <td>
                      {moment(data.date1).format("DD/MM/YYYY hh:mm a")}
                    </td>
                    <td>
                      {data.comments}
                    </td>
                  </tr>
                );
              })
            : "No Data Available"}
        </tbody>
      </Table>
      
    </>
  );
}
