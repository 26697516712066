import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import PaymentTable from "../payment/Payment.table";
import StudentEnrollment from "../StudentEnrollment";
import StudentSessions from "../StudentSessions";
import DealsTable from "../DealsTable/DealsTable";
import EnrollmentDealsTable from "../DealsTable/EnrollmentDealsTable";

const StudentTables = (props: any) => {
  return (
    <>
      <Container>
        <div className="fw-bold fs-4 mt-3"></div>
        <Row className="mt-3">
          <Col>
            <StudentSessions id={props.id} />
          </Col>
        </Row>
        <Row className="mt-3">
          <Col md={12}>
            <StudentEnrollment id={props.id} />
          </Col>
        </Row>
        <Row className="mt-3">
          <Col md={12}>
            <EnrollmentDealsTable id={props.id} />
          </Col>
        </Row>
        <Row className="mt-3">
          <Col md={12}>
            <PaymentTable id={props.id} />
          </Col>
        </Row>
        <Row className="mt-3">
          <Col md={12}>
            <DealsTable id={props.id} />
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default StudentTables;
