import React, { useEffect, useState } from 'react'
import { AdminService } from '../../../../../services/admin.service';
import toast from 'react-hot-toast';
import CardBasic from '../../../../../components/Card/card.basic';
import Heading from '../../TableHeading/Heading';
import { Dropdown, Form, Table } from 'react-bootstrap';
import { FontBold, NormalText } from '../../../../../components/Text/TableText.formats';
import moment from 'moment';
import CustomToggle from '../../../../../components/Menu/CustomMenu';
import { BiDotsVertical } from 'react-icons/bi';
import { IoPencilOutline } from 'react-icons/io5';
import SideModal from '../../../../../components/SideModal/SideModal';

const EnrollmentDealsTable = ({ id }: any) => {
    const [showDealEditModal, setShowDealEditModal] = useState<boolean>(false);
    const [dealId, setDealId] = useState<any>()
    const [paymentDetailsId, setPaymentDetailsId] = useState<any>();
    const [loading, setLoading] = useState<boolean>(false);
    const [enrollmentId, setEnrollmentId] = useState<any>();
    const [tableData, setTableData] = useState<any[]>();


    const handleEditDeal = (e: any) => {
        setDealId(e.target.value);
        return;
    };


    const changeDealId = async () => {
        console.log(dealId);
        setLoading(true);
        await AdminService.editFreshWorksDealId(enrollmentId, paymentDetailsId, dealId).then((res) => {
            if (res.status == 200) {
                toast.success("Deal id updated");
                fetchStudentEnrollment();
                setShowDealEditModal(false);
                setLoading(false);
            }
            else {
                toast.error("Unable to update deal id");
                setLoading(false);
            }
        })
            .catch((error) => {
                console.log(error);
                setShowDealEditModal(false);
                setLoading(false);
            })
        return;
    }


    const fetchStudentEnrollment = async () => {
        await AdminService.getStudentEnrollment({ id })
            .then((res) => {
                if (res.status === 200) {
                      setTableData(res?.data);
                    console.log(res?.data, "table data");
                }
            })
            .catch((err) => {
                toast.error("Unable to fetch Student Enrollment");
            });
    };


    useEffect(() => {
        fetchStudentEnrollment();
    }, []);


    return (
        <CardBasic
            body={
                <>
                    <Heading heading="Deals Enrollment Detail" />
                    <div
                        className="m-4"
                        style={{ maxHeight: "500px", overflow: "scroll" }}
                    >
                        <Table striped borderless hover responsive size="sm">
                            <thead>
                                <tr className="dhun-table-header text-secondary">
                                    <th>#</th>
                                    <th> Enrollment Id</th>
                                    <th>Total Price</th>
                                    <th>Payment Status</th>
                                    <th>Receipt Id</th>
                                    <th>Deal Id</th>
                                </tr>
                            </thead>
                            <tbody>
                                {tableData && tableData.length > 0
                                    ? tableData.map((data: any, index: any) => {
                                        return data.payment_details.map((payment: any, i: any) => (
                                            <tr key={`${data._id}-${i}`}>
                                                <td>
                                                    <NormalText value={index + 1} />
                                                </td>
                                                <td>
                                                    <FontBold value={data.enrollmentId} />
                                                </td>
                                                <td>
                                                    <FontBold value={payment.total_price || "--"} />
                                                </td>
                                                <td>
                                                    <FontBold value={payment.payment_status || "--"} />
                                                </td>
                                                <td>
                                                    <FontBold value={payment.receipt || "--"} />
                                                </td>
                                                <td>
                                                    <FontBold value={payment.freshworksDealId || "--"} />
                                                </td>
                                                <td>
                                                    <Dropdown>
                                                        <Dropdown.Toggle as={CustomToggle} id="dropdown-custom-components">
                                                            <BiDotsVertical />
                                                        </Dropdown.Toggle>
                                                        <Dropdown.Menu>
                                                            <Dropdown.Item
                                                                className="text-secondary fs-12 align-items-center d-flex fw-bold"
                                                                onClick={() => {
                                                                    setDealId(payment.freshworksDealId || "");
                                                                    setPaymentDetailsId(payment._id || "");
                                                                    setEnrollmentId(data.enrollmentId);
                                                                    setShowDealEditModal(true);
                                                                }}
                                                            >
                                                                <IoPencilOutline className="text-primary me-1" /> Edit Deal Id
                                                            </Dropdown.Item>
                                                        </Dropdown.Menu>
                                                    </Dropdown>
                                                </td>
                                            </tr>
                                        ));
                                    })
                                    : "No Data Available"}
                            </tbody>
                        </Table>
                    </div>
                    <SideModal
                        show={showDealEditModal}
                        handleClose={() => setShowDealEditModal(false)}
                        body={
                            <div className="w-100  mt-1" style={{ height: "90vh" }}>
                                <Form.Group className="mt-2">
                                    <Form.Label className="fw-bold text-secondary fs-12">
                                        Deal Id
                                    </Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="dealId"
                                        value={dealId}
                                        onChange={(e) => { handleEditDeal(e) }}
                                    />
                                </Form.Group>
                                <button
                                    className="btn btn-primary btn-block mt-6"
                                    onClick={(e) => {
                                        console.log(e);
                                        changeDealId();
                                    }}
                                    disabled={loading}
                                >
                                    {loading && (
                                        <span className="spinner-border spinner-border-sm mr-2"></span>
                                    )}
                                    Edit Deal Id
                                </button>
                            </div>
                        }
                        title="Edit Deal"
                    />
                </>
            }
        />
    )
}

export default EnrollmentDealsTable