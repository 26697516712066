import React, { useEffect, useState } from 'react'
import { Badge, Button, Form } from 'react-bootstrap';
import StringHelper from '../Helper/string.helper';
import SideModal from '../SideModal/SideModal';
import { BookingService } from '../../services/booking.service';
import Select from "react-select";
import { AdminService } from '../../services/admin.service';
import toast from 'react-hot-toast';
import Loader from '../Loader/Loader.index';

interface IRoles {
    show: any,
    onClose: any
}

const AddClassesNoModal = ({ show, onClose }: IRoles) => {


    const [classes, setClasses] = useState<any>();
    const [loading, setLoading] = useState<boolean>(false);

    console.log({ classes }, "classes modal---------")
    const handleExtraClassesChange = (bundleClass: string, classValue: number) => {
        setClasses((prev: any) => ({
            ...prev,
            [bundleClass]: classValue,
        }));
    };

    const bundlePackages = ["4", "8", "12", "16", "24", "36", "48", "72", "96"];


    const getExtraClassesByAdmin = async () => {
        await AdminService.getExtraClassesByAdmin(show).then((res) => {
            if (res.status == 200) {
                console.log(res.data);
                setClasses(res.data.user.superAdminSetting.allowedExtraClasses);
            }
        })
    }


    const updateExtraClasses = async () => {
        setLoading(true);
        await AdminService.updateExtraClassesByAdmin(show, classes).then((res) => {
            if (res.status == 200) {
                toast.success('Extra classes updated');
                getExtraClassesByAdmin();
            }
        })
            .catch((error) => {
                console.log(error);
            })
        setLoading(false);
    }


    useEffect(() => {
        if (show) {
            getExtraClassesByAdmin();
        }
    }, [show])


    return (
        <SideModal
            title="Extras classes allowed"
            show={show ? true : false}
            handleClose={onClose}
            body={
                <>
                    <div style={{ height: "80vh" }}>
                        <div style={{ height: "80vh" }}>
                            {bundlePackages?.map((bundle: string, index: number) => (
                                <Form.Group key={index} className="mb-3 d-flex align-items-center gap-3">
                                    <Form.Label className="fw-bold text-secondary">{bundle}:</Form.Label>
                                    <Form.Control
                                        type="number"
                                        onChange={(e) => handleExtraClassesChange(bundle, Number(e.target.value))}
                                        defaultValue={classes && classes[bundle]}
                                    />
                                </Form.Group>
                            ))}
                        </div>

                    </div>
                    <div>
                        <Button className='w-100' disabled={loading ? true : false} onClick={() => { updateExtraClasses() }}>
                            {loading ? <Loader /> : "Update"}
                        </Button>
                    </div>
                </>
            }
        />
    )
}

export default AddClassesNoModal