import moment from 'moment';
import React, { useEffect, useState } from 'react'
import { Button, Card, Modal, Table } from 'react-bootstrap';
import { toast } from 'react-hot-toast';
import { useNavigate } from 'react-router';
import CardBasic from '../../../../../components/Card/card.basic';
import { FontBold, NormalText } from '../../../../../components/Text/TableText.formats';
import StudentService from '../../../../../services/student.service';
import Heading from '../../TableHeading/Heading';
import { AdminService } from '../../../../../services/admin.service';
import { FaEdit } from 'react-icons/fa';
import Select from 'react-select';


interface IPayment{
    id:string
 
}
const DealsTable = ({id}:IPayment) => {

    const [tableData,setTableData]=useState<any>();
  const navigate: any = useNavigate();

  const [showStageUpdate, setShowStageUpdate] = useState(undefined);
  const [stage, setStage] = useState<any>();



    const fetchFreshWorksDeals = async (id:any)=>{
        await AdminService.getFreshworksDeals(id).then((res)=>{
            if(res.status==200){
              setTableData(res.data.deals.deals);
            }
        })
        .catch((error)=>{
            toast.error('Unable to fetch deals');
        });
    }

    const updateFreshworksStage = async (id:any)=>{
      if(!showStageUpdate){
        toast.error('Please select a deal to update');
        return;
      }
      await AdminService.updateFreshworksStage({stage: stage.value, deal_id: showStageUpdate}).then((res)=>{
          if(res.status==200){
            toast.success('Deal updated successfully');
            setShowStageUpdate(undefined);
          }
      })
      .catch((error)=>{
          toast.error('Unable to fetch deals');
      });
  }


    useEffect(()=>{
        fetchFreshWorksDeals(id);
    }, []);


  return (
    <>
    <Modal show={showStageUpdate} onHide={() => setShowStageUpdate(undefined)}>
      <Modal.Header closeButton>
        <Modal.Title>Update Deal</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div>
            <Select
              options={[
                {label:'Paid',value:'Paid'},
                {label:'Partial',value:'Partial'},
                {label:'Pending',value:'Pending'}
              ]}
              onChange={setStage}
            />
        </div>
        <div>
          <Button onClick={updateFreshworksStage}>
            Update
          </Button>
        </div>
        
      </Modal.Body>
    </Modal>
    <CardBasic
      body={
        <>
          <Heading heading="Deals Details" />
          <div
            className="m-4"
            style={{ maxHeight: "500px", overflow: "scroll" }}
          >
            <Table striped borderless hover responsive size="sm">
              <thead>
                <tr className="dhun-table-header text-secondary">
                  <th>#</th>
                  <th>Deals Id</th>
                  <th>Deals Name</th>
                  <th>Status</th>
                  <th>Amount</th>
                </tr>
              </thead>
              <tbody>
                {tableData && tableData?.dealsId?.length > 0
                  ? tableData?.dealsId?.map((data: any, index: any) => {
                      return (
                        <tr key={data?._id}>
                          <td>
                            <NormalText value={index + 1} />
                          </td>
                          <td>
                            <FontBold value={data.id} />
                          </td>
                          <td>
                            <FontBold value={data.name || "--"} />
                           
                          </td>
                          <td>
                            <FontBold value={data.status || "--"} />
                            <FaEdit
                            className='ms-2'
                             onClick={()=>{
                              setShowStageUpdate(data.id)
                            }}/>
                          </td>
                          <td>
                            <FontBold value={data.amount || "--"} />
                          </td>
                        </tr>
                      );
                    })
                  : "No Data Available"}
              </tbody>
            </Table>
          </div>
        </>
      }
    />
    </>
  );
}

export default DealsTable;
